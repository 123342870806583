<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <!-- <v-text-field
          v-model="formData.idPinjaman"
          :rules="[(v) => !!v || 'Mohon pilih ID pinjaman']"
          label="ID Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formData.idPinjaman"
          :items="statesLoan"
          item-text="id_pinjaman"
          item-value="id"
          label="ID Pinjaman"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.borrower"
          :rules="[(v) => !!v || 'Mohon pilih borrower']"
          label="Borrower"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formData.borrower"
          :items="statesBorrower"
          item-text="id_borrower"
          item-value="id"
          label="Borrower"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.penyelenggara"
          :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
          label="Penyelenggara"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formData.noPerjanjianLender"
          :rules="[(v) => !!v || 'Mohon isi no. perjanjian lender']"
          label="Nomor Perjanjian Lender"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.lender"
          :rules="[(v) => !!v || 'Mohon pilih lender']"
          label="Lender"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formData.lender"
          :items="states"
          item-text="id_lender"
          item-value="id"
          label="Lender"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPerjanjianLenderMenu"
          v-model="tanggalPerjanjianLenderMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalPerjanjianLender"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalPerjanjianLender"
              label="Tanggal Perjanjian Lender"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalPerjanjianLender"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPerjanjianLenderMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPerjanjianLenderMenu.save(
                  formData.tanggalPerjanjianLender
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPenawaranPemberianPinjamanMenu"
          v-model="tanggalPenawaranPemberianPinjamanMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalPenawaranPemberianPinjaman"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalPenawaranPemberianPinjaman"
              label="Tanggal Penawaran Pemberian Pinjaman"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              required
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalPenawaranPemberianPinjaman"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPenawaranPemberianPinjamanMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPenawaranPemberianPinjamanMenu.save(
                  formData.tanggalPenawaranPemberianPinjaman
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.noVaLender"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi no. VA lender']"
          label="Nomor VA Lender"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nilaiPenawaranPinjaman"
          :items="nilaiPenawaranPinjamanOptions"
          type="number"
          item-text="name"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih nilai penawaran pinjaman']"
          label="Nilai Penawaran Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nilaiPenawaranDisetujui"
          :items="nilaiPenawaranDisetujuiOptions"
          item-text="name"
          type="number"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih nilai penawaran disetujui']"
          label="Nilai Penawaran Disetujui"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <!-- <v-col cols="12" md="6">
        <v-select
          v-model="formData.report"
          :items="reportOptions"
          item-text="name"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih report']"
          label="Report"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col> -->
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formLoanLender: Object,
  },
  data: () => ({
    valid: true,
    loading: false,
    formData: {
      idPinjaman: null,
      borrower: null,
      penyelenggara: "",
      noPerjanjianLender: "",
      lender: null,
      tanggalPerjanjianLender: "",
      tanggalPenawaranPemberianPinjaman: "",
      noVaLender: "",
      nilaiPenawaranPinjaman: 0,
      nilaiPenawaranDisetujui: 0,
      report: 0,
    },
    tanggalPerjanjianLenderMenu: false,
    tanggalPenawaranPemberianPinjamanMenu: false,
    idPinjamanOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    borrowerOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    lenderOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    nilaiPenawaranPinjamanOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    nilaiPenawaranDisetujuiOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    reportOptions: [
      {
        name: "Ya",
        value: 0,
      },
      {
        name: "Tidak",
        value: 1,
      },
    ],
    borrowerDialog: false,
    hasSaved: false,
    model: null,
    states: [],
    statesBorrower: [],
    statesLoan: [],
  }),

  async mounted() {
    this.formData = this.formLoanLender;
    this.loading = "primary";

    await store.dispatch("office/fetchAllBorrower").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLoan").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLender").then((r) => {
      if (r) this.loading = false;
    });

    this.states = store.state.office.allLender;
    this.statesBorrower = store.state.office.allBorrower;
    this.statesLoan = store.state.office.allLoan;
  },
};
</script>
